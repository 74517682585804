
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import {computed, defineComponent, ref, watch } from "vue"
import { hideModalById, listenerHideModal } from "@/core/helpers/dom";
import { getModule } from 'vuex-module-decorators'
import {  Form, Field } from "vee-validate";
import * as Validations from "yup";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import SaleDetail from "@/models/sales/SaleDetail";
import TaxesModule from "@/store/modules/taxes/modules/new-taxes";
// import Select from "@/components/forms/Select.vue"

export default defineComponent({
    components:{
        DynamicContentModal,
        Form,
        Field
        // Select
    },
    setup() {
        //Propiedades
        const moduleNewSale = getModule(NewSaleModule);
        const formLiquidation = ref();
        const moduleTaxes = getModule(TaxesModule);
        const haveDiscont = ref(true);
        const porcentDiscont = ref<any>([
        ]);

        const infoConcept = ref<SaleDetail>({
            productId: "",
            code: "",
            productName: "",
            unitOfMeasurementName: "",
            saleId: "",
            unitOfMeasurementId: "",
            discountPersent: 0,
            taxPersent: 0,
            price: 0,
            amount: 0,
            discount: 0,
            tax: 0,
            total: 0
            , unit: ''
            ,import: 0
            ,subTotal: 0
            ,status: true
            ,buyerPrice:0,
            maximunDiscount:0,
            minimunDiscount: 0,
            publicPrice: 0,
            wholesalePrice: 0
        })

        let validationSchema = Validations.object().shape({
            amount: Validations.number().min(0, 'Valor minimo 0').typeError('Ingrese una cantidad valida').required().label("amount"),
            dto:  Validations
                .number()
                .typeError('Ingrese una cantidad valida')
                .label("dto")
        });

        const formatNumeric = (number) => {
          var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2
          });
          
          return formatter.format(number);
        }

        const calculatePercentage = (price: number, amount: number, percentage: number) => {
            let result = 0;
            result = ((amount * price) * percentage) / 100;
            return result;
        }

        const submitForm = (data: any) => {
            moduleNewSale.DELETE_PRODUCT(infoConcept.value.productId);
            const discontPersent = calculatePercentage(infoConcept.value.price, data.amount, data?.dto ?? 0 )

            data.productId = infoConcept.value.productId;
            data.productName = infoConcept.value.productName;
            data.unitOfMeasurementName = infoConcept.value.unitOfMeasurementName;
            data.discount =  parseFloat((discontPersent).toFixed(2)),
            data.import =  parseFloat(((data.amount * infoConcept.value.price) - discontPersent).toFixed(2)),
            data.subTotal = parseFloat(((data.amount * infoConcept.value.price) - (discontPersent)).toFixed(2)),
            data.tax = parseFloat(((data.subTotal - discontPersent) * (infoConcept.value.taxPersent / 100)).toFixed(2));
            data.total =  parseFloat((data.amount * infoConcept.value.price).toFixed(2)); 
            data.price = infoConcept.value.price;
            data.unitOfMeasurementId = infoConcept.value.unitOfMeasurementId;
            data.code = infoConcept.value.code;
            data.taxPersent = infoConcept.value.taxPersent;
            data.discountPersent = data?.dto ?? 0;
            data.status = true;
            data.id = infoConcept.value.id;
            data.saleId = infoConcept.value.saleId;

            moduleNewSale.UPDATE_PRODUCT(data);
            hideModalById("#EditProductForm");

            // haveDiscont.value = true;
            porcentDiscont.value = [];
        };

        const taxesInfo = computed(() => moduleTaxes.getTaxeInfo);
    
        watch(() => moduleNewSale.getSalectProduct, async (currentValue: SaleDetail) => {
                porcentDiscont.value = [];
                infoConcept.value = currentValue;

                // if(infoConcept.value.minimunDiscount > 0){
                //     for (let i = infoConcept.value.minimunDiscount; i <= infoConcept.value.maximunDiscount; i++) {
                //         porcentDiscont.value.push({
                //         id: i,
                //         name: `${i } %`
                //         });
                //     }
                //     haveDiscont.value = false;
                // }

                if(infoConcept.value.minimunDiscount > 0){
                    validationSchema.fields.dto = Validations
                                      .number()
                                      .min(infoConcept.value.minimunDiscount, `Minimo desuento ${infoConcept.value.minimunDiscount}`)
                                      .max(infoConcept.value.maximunDiscount, `Maximo descuento ${infoConcept.value.maximunDiscount}`)
                                      .typeError('Ingrese una cantidad valida')
                                      .required()
                                      .label("dto");

                    haveDiscont.value = false;
                }


                formLiquidation.value.setValues({
                   amount: currentValue.amount,
                   dto: currentValue.discountPersent
                });

        });
        
        const resetForm = () => {
            moduleNewSale.RESET_EDIT_PRODUCT();
            formLiquidation.value.resetForm();
        }

        return {
            formLiquidation,
            validationSchema,
            infoConcept,
            porcentDiscont,
            haveDiscont,
            calculatePercentage,

            formatNumeric,

            submitForm,
            resetForm
        }

    },
    methods:{
        closeListener(){
            this.resetForm();
        },
        triggerHidden: function(){
            listenerHideModal("#EditProductForm", this.closeListener);
        },
    },
    mounted(){
        this.triggerHidden();
    }
})
